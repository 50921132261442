import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'popup-buy',
  computed: {
    ...mapGetters({
      popupBuyForm: 'event/popupBuyProduct',
      buyForm: 'event/buyForm',
    })
  },
  methods: {
    ...mapMutations({
      changeShowBuyPopup: `event/SHOW_POPUP_BUY_FORM`,
    })
  },
  destroyed() {
    this.changeShowBuyPopup({title: '', price: ''})
  }
}
